import React, { useEffect, useState } from "react";
import "../styles/index.scss";
import { useAuthContext } from "../context/ApolloContext";
import { Navigate } from "react-router-dom";
import { BadgeCard } from "../components/BadgeCard";
import { Banner } from "../components/Banner";
import { SideBar } from "../components/SideBar";
import { useAuth } from "../hooks/auth";
import { Spinner } from "../components/Spinner";

export const FrontPage = () => {
  const [ loading, setLoading ] = useState(true);
  const { isLoggedIn, metadata } = useAuthContext();

  const { getQuestions, getMetadata, getResources } = useAuth();

  const refreshData = async () => {
    setLoading(true);
    await getResources();
    await getQuestions();
    await getMetadata();
    setLoading(false);
  };

  useEffect(() => {
    refreshData();
  }, []);

  const defaultArr = {
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
  };

  const status = {
    seed: metadata["seedBadgeCompletion"] || defaultArr,
    sprout: metadata["sproutBadgeCompletion"] || defaultArr,
    grow: metadata["growBadgeCompletion"] || defaultArr,
    thrive: metadata["thriveBadgeCompletion"] || defaultArr,
  };

  if (!isLoggedIn) {
    return <Navigate to={"/login"} />;
  } else {
    return (
      <div className="page">
        <div className="front-page">
          <SideBar tabIndex={0} extraClasses="onPage" />
          {!!loading ?
            <Spinner loading /> : 
            (<div className="content-container">
              <Banner />
              <h3>Collect All 4 Badges</h3>
              <div className="badge-cards">
                <BadgeCard 
                  badge="seed"
                  description="Beginner-level questions gauge users' familiarity with Indigenous Peoples and Reconciliation fundamentals."
                  status={status.seed}
                />
                <BadgeCard 
                  badge="sprout"
                  description="Intermediate-level questions to learn and build on Reconciliation fundamentals."
                  status={status.sprout}
                />
                <BadgeCard 
                  badge="grow"
                  description="Advanced-level questions to further your understanding of Reconciliation."
                  status={status.grow}
                />
                <BadgeCard 
                  badge="thrive"
                  description="Expert-level questions to explore more complex topics and learning related to Indigenous Peoples and Reconciliation."
                  status={status.thrive}
                />
              </div>
            </div>
            )
          }
        </div>
      </div>
    );
  }
};
