import React from "react";
import { Badge } from "./Badge";
import PropTypes from "prop-types";
import "../styles/index.scss";

export const TeamBadgeCard = (props) => {
  const {
    name,
    seedBadgeCompletion,
    growBadgeCompletion,
    sproutBadgeCompletion,
    thriveBadgeCompletion,
  } = props;

  return (
    <div className="TeamBadge">
      <p className="name">{name}</p>
      <div className="badge-container">
        <Badge badge="seed" diameter={"50"} status={seedBadgeCompletion} />
        <Badge badge="grow" diameter={"50"} status={growBadgeCompletion} />
        <Badge badge="sprout" diameter={"50"} status={sproutBadgeCompletion} />
        <Badge badge="thrive" diameter={"50"} status={thriveBadgeCompletion} />
      </div>
    </div>
  );
};

TeamBadgeCard.propTypes = {
  name: PropTypes.string.isRequired,
  seedBadgeCompletion: PropTypes.object.isRequired,
  growBadgeCompletion: PropTypes.object.isRequired,
  sproutBadgeCompletion: PropTypes.object.isRequired,
  thriveBadgeCompletion: PropTypes.object.isRequired,
};
