import React from "react";
import PropTypes from "prop-types";
import { Badge } from "./Badge";
import { Button } from "./Button";

export const Modal = (props) => {
  const { badge, questionNum } = props;

  const status = {};
  status[questionNum-1] = true;
  
  return (
    <div className="modal">
      <div className="content-container">
        <p>{"You've collected a piece of the " + badge + " badge!"}</p>
        <Badge badge={badge} status={status} position={questionNum}/>
        <Button 
          type="primary"
          text="Continue"
          link="/"
        />
      </div>
    </div>
  );
};

Modal.propTypes = {
  badge: PropTypes.string.isRequired,
  questionNum: PropTypes.string.isRequired,
};
