import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuthContext } from "../context/ApolloContext";
import "../styles/index.scss";
import { QuestionCard } from "../components/QuestionCard";
import { Badge } from "../components/Badge";
import { Breadcrumb } from "../components/Breadcrumb";

export const SelectQuestionPage = () => {
  const { isLoggedIn } = useAuthContext();
  if (!isLoggedIn) {
    return <Navigate to={"/login"} />;
  }

  const { questions, metadata } = useAuthContext();

  const { pathname, state } = useLocation();
  const badge = pathname.split("/")[1];
  const badgeStatus = metadata[badge + "BadgeCompletion"];

  if (!state?.questionNum && pathname !== ("/" + badge)) {
    return <Navigate to={"/"} />;
  }

  const pageClass = [
    "question-list",
    pathname !== ("/" + badge) ? "hide" : "",
  ];

  const questionCards = Object.keys(questions[badge]).map((num) => {
    const questionData = questions[badge][num];
    const questionNumber = num;
    const questionLabel = questionData.question.post_content;
    const status = badgeStatus[num-1];
    
    return (
      <QuestionCard
        key={questionData.question.ID}
        badge={badge}
        questionNumber={questionNumber}
        question={questionLabel}
        completed={status}
      />
    );
  });

  return (
    <div className="page">
      <div className={"question-page " + badge}>
        <div className="heading-container">
          <Breadcrumb path={pathname} />
          {pathname === ("/" + badge)
            ? <Badge badge={badge} status={badgeStatus} />
            : <Badge badge={badge} status={{}} position={state.questionNum}/>
          }
        </div>
        <div className={pageClass.join(" ")}>
          {pathname === ("/" + badge) && questionCards}
        </div>
        <Outlet />
      </div>
    </div>
  );
};
