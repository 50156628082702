import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export const Breadcrumb = (props) => {
  const { path } = props;

  const pathItems = path.split(/(?=\/)/g);

  const setupLinks = (pathItems) => {
    switch (pathItems.length) {
      case 1:
        return (
          <Link className="badge-link active" to={pathItems[0]}>{pathItems[0].substring(1)}</Link>
        );
      case 2:
        if (pathItems[1] == "/select-actions") {
          return (
            <>
              <Link className="badge-link" to={pathItems[0]}>{pathItems[0].substring(1)}</Link>
              {" / "}
              <Link className="select-actions-link active" to={path}>Select Actions</Link>
            </>
          );
        } else {
          return (
            <>
              <Link className="badge-link" to={pathItems[0]}>{pathItems[0].substring(1)}</Link>
              {" / "}
              <Link className="actions-link active" to={path}>Actions</Link>
            </>
          );
        }
    }
  };

  return (
    <div className="breadcrumb">
      <Link className="home-link" to="/">Home</Link>
      {" / "}
      {setupLinks(pathItems)}
    </div>
  );
};

Breadcrumb.propTypes = {
  path: PropTypes.string.isRequired,
};