import React, { useState } from "react";
import { useAuth } from "../hooks/auth";
import { useAuthContext } from "../context/ApolloContext";
import logo from "../assets/ctr-logo.png";
import { Navigate } from "react-router-dom";
import { Spinner } from "../components/Spinner";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

export const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login, error, status } = useAuth();
  const { isLoggedIn } = useAuthContext();

  const handleUpdateUsername = (event) => {
    setUsername(event.target.value);
  };
  
  const handleUpdatePassword = (event) => {
    setPassword(event.target.value);
  };

  const onLogin = (e) => {
		e.preventDefault();
		login( username, password );
	};

  if (isLoggedIn) {
    return <Navigate to={"/"} />;
  } else {
    return (
      <div className="login-page">
        {status === "resolving" && <Spinner loading={true} />}
        <div className="container-description">
          <Link to="/"><img className="logo" src={logo} alt="ConnectR Logo" /></Link>
          <div className="description">
            <h1>Start Your Journey Here</h1>
            <p>ConnectR guides your journey towards truth and reconciliation. Find calls to action to grow what you know, encourage a shared future and generate change.</p>
          </div>
          <div className="attribution">
            <a target='_blank' rel='noopener noreferrer' href="https://www.freepik.com/free-photo/wheatfield-sunset_945755.htm#fromView=search&page=1&position=2&uuid=be77f9ce-bf2b-48eb-a7f4-d5433c1dc890">Image by bedneyimages on Freepik</a>
          </div>
        </div>
        <div className="container-form">
          <h2>Welcome Back</h2>
          <form className="form" onSubmit={onLogin}>
            <label>
              <p>Email</p>
              <input type="email" value={username} onChange={handleUpdateUsername} required />
            </label>
            <label>
              <div className="input-password-heading">
                <p>Password</p>
                <a href="https://www.beaconnectr.org/wp-login.php?action=lostpassword">Forgot Your Password?</a>
              </div>
              <input type="password" value={password} onChange={handleUpdatePassword} required/>
            </label>
            <div className="submit-btn-container">
              {error.isError && <p className="error">{parse(error.message)}</p>}
              {status === "resolving" ? <input type="submit" value="Sign In" disabled /> : <input type="submit" value="Sign In" /> }
            </div>
          </form>
          <p className="additional-links">{"Haven't had an account? "}<Link to="/signup">Sign Up</Link></p>
          {/* <p className="additional-links">Or are you wanting to <Link to="/">Sign up an Organization</Link></p> */}
        </div>
      </div>
    );
  }
};
