import React, { useState, useEffect } from "react";
import adminImg from "../assets/admin.png";
import homeImg from "../assets/home.png";
import groupImg from "../assets/group.png";
import logoutImg from "../assets/logout.png";
import settingsImg from "../assets/settings.png";
import { useAuth } from "../hooks/auth";
import PropTypes from "prop-types";
import { api } from "../util/axios";
import { Link } from "react-router-dom";

export const SideBar = (props) => {
  const { logout } = useAuth();
  const tabIndex = props?.tabIndex || 0;
  const [isAdmin, setIsAdmin] = useState(false);

  const handleLogout = async () => {
    await logout();
    window.location.replace("/");
  };

  const isUserAdmin = () => {
    api.get('https://beaconnectr.org/wp-json/api/v1/user/admin').then((data) => {
      setIsAdmin(data.data.isAdmin);
    });
  }; 

  useEffect(() => {
    isUserAdmin();
  });
  const classes = [
    "Sidebar",
    "filter",
    props?.extraClasses
  ];

  return (
    <div className={classes.join(" ")}>
      <div className="filter">
        <div className="title-container">
          <p className="title">Menu</p>
        </div>
        <div className="container">
          <Link className={tabIndex === 0 ? "active tab" : "tab"} to={"/"}>
            <img src={homeImg}/>
            <p className="tab-item">Home</p>
          </Link>
          <Link className={tabIndex === 1 ? "active tab" : "tab"} to={"/team"}>
            <img src={groupImg}/>
            <p className="tab-item">Team</p>
          </Link>
          <Link className={tabIndex === 2 ? "active tab" : "tab"} to={"/settings"}>
            <img src={settingsImg}/>
            <p className="tab-item">Settings</p>
          </Link>
          {isAdmin && <Link className={tabIndex === 3 ? "active tab" : "tab"} to={"/admin"}>
            <img src={adminImg}/>
            <p className="tab-item">Admin</p>
          </Link>}
        </div>
        <div className="logout tab" onClick={handleLogout}>
          <img src={logoutImg}/>
          <p className="tab-item">Log Out</p>
        </div>
      </div>
    </div>
  );
};

SideBar.propTypes = {
  admin: PropTypes.bool,
  callback: PropTypes.func,
  tabIndex: PropTypes.number,
  extraClasses: PropTypes.string,
};
