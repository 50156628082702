import React from "react";
import PropTypes from "prop-types";

export const Spinner = (props) => {
  const { loading } = props;

  if (loading) {
    return <div className="Spinner"></div>;
  }
};

Spinner.propTypes = {
  loading: PropTypes.bool,
};