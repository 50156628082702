import React, { useEffect, useState } from "react";
import "../styles/index.scss";
import { useAuth } from "../hooks/auth";
import { useAuthContext } from "../context/ApolloContext";
import { Navigate } from "react-router-dom";
import { api } from "../util/axios";
import { IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { SideBar } from "../components/SideBar";
import { Spinner } from "../components/Spinner";

export const SettingsPage = () => {
  const { isLoggedIn } = useAuthContext();  
  if (!isLoggedIn) {
    return <Navigate to={"/login"} />;
  }

  const { user } = useAuthContext();
  const { logout } = useAuth();
  const [originalData, setOriginalData] = useState({
    display_name: "",
    email: "",
    password: ""
  });
  const [individualData, setIndividualData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: ""
  });
  const [errors, setErrors] = useState({
    firstName: false,
    firstNameError: "",
    email: false,
    emailError: "",
    password: false,
    passwordError: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const [loading, setLoading] = useState(true);

  const getUserData = () => {
    api.get('https://beaconnectr.org/wp-json/api/v1/user').then((data) => {
      const user = data.data.data.data;
      setIndividualData({
        firstName: user.display_name.split(" ")[0],
        lastName: user.display_name.split(" ")[1],
        email: user.user_email,
        password: user.user_pass
      });
      setOriginalData({
        display_name: user.display_name,
        email: user.user_email,
        password: user.user_pass
      });
      setLoading(false);
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleUpdateIndividual = (event) => {
    const { value, name } = event.target;

    setIndividualData(prevState => ({
      ...prevState,
      [name] : value.trim()
    }));
  };

  const didDataUpdate = (newData, oldData) => {
    return newData != oldData ? true : false;
  };

  const onUpdate = (e) => {
    e.preventDefault();

    setLoading(true);

    let submitError = false;
    let updateData = {};
    
    Object.keys(individualData).map((key) => {
      const errorKey = key + "Error";
      const value = individualData[key];

      switch (key) {
        case "firstName":
        case "password":
          if (individualData[key] == "") {
            submitError = true;
            setErrors(prevState => ({
              ...prevState,
              [key] : true,
              [errorKey] : "This field cannot be empty.",
            }));
          } else {
            setErrors(prevState => ({
              ...prevState,
              [key] : false,
              [errorKey] : "",
            }));

            if (key == "firstName") {
              const displayName = value + " " + individualData['lastName'];
              if (didDataUpdate(displayName, originalData['display_name'])) {
                updateData['display_name'] = displayName;
              }
            } else {
              if (didDataUpdate(value, originalData['password'])) {
                updateData['password'] = value;
              }
            }
          }
          break;
        case "email":
          if (individualData[key] == "") {
            submitError = true;
            setErrors(prevState => ({
              ...prevState,
              [key] : true,
              [errorKey] : "This field cannot be empty.",
            }));
          } else if (!individualData[key].includes("@")) {
            submitError = true;
            setErrors(prevState => ({
              ...prevState,
              [key] : true,
              [errorKey] : "Your email format appears to be incorrect.",
            }));
          } else {
            setErrors(prevState => ({
              ...prevState,
              [key] : false,
              [errorKey] : "",
            }));
            if (didDataUpdate(value, originalData['email'])) {
              updateData['email'] = value;
            }
          }
          break;
      }
    });

    if (!submitError && Object.keys(updateData).length !== 0) {
      Object.keys(updateData).map((key) => {
        api.post('https://beaconnectr.org/wp-json/api/v1/update-user', {
          update_key: key,
          update_val: updateData[key],
        }).then((data) => {
          console.log(data.data);
        }).catch((err) => {
          console.log(err);
        }).finally(() => {
          setLoading(false);
        });
      });
    } else {
      setLoading(false);
    }
  };

  const deactivateAccount = () => {
    const userID = user.data.ID;

    api.post('https://beaconnectr.org/wp-json/api/v1/user-delete', {
      user_id: userID,
    }).then((data) => {
      console.log(data);
    }).catch((err) => {
      console.log(err);
    });

    logout();
  };

  /*
    Do we have to reset user in local storage because of the changes, does that effect the logged in user?
  */
  if (loading) {
    return (
      <div className="page">
        <div className="settings-page">
          <SideBar tabIndex={2} extraClasses="onPage" />
          <Spinner loading />
        </div>
      </div>
    );
  } else {
    return (
      <div className="page">
        <div className="settings-page">
          <SideBar tabIndex={2} extraClasses="onPage" />
          {loading ?
            <Spinner /> : (
              <div className="content-container">
              <h3>Settings</h3>
              <form className="form" onSubmit={onUpdate}>
                <div className="name-container">
                  <label>
                    <p>First Name</p>
                    <input type="text" name={"firstName"} value={individualData.firstName} onChange={handleUpdateIndividual} />
                    {errors.firstName && <p className="error">{errors.firstNameError}</p>}
                  </label>
                  <label>
                    <p>Last Name</p>
                    <input type="text" name={"lastName"} value={individualData.lastName} onChange={handleUpdateIndividual} />
                  </label>
                </div>
                <label>
                  <p>Email</p>
                  <input type="text" name={"email"} value={individualData.email} onChange={handleUpdateIndividual} />
                  {errors.email && <p className="error">{errors.emailError}</p>}
                </label>
                <label>
                  <p>Password</p>
                  <div className="password-container">
                    <input type={showPassword ? "text" : "password"} name={"password"} value={individualData.password} onChange={handleUpdateIndividual} />
                    <IconButton
                      className="visibility-icon"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    {errors.password && <p className="error">{errors.passwordError}</p>}
                  </div>
                </label>
                <div className="buttons-container">
                  <button className="button" type="submit" disabled={loading}>{loading ? "Saving ..." : "Save Changes"}</button>
                  <button className="button-link" type="button" onClick={deactivateAccount}>Deactivate Account</button>
                </div>
              </form>
            </div>
            )
          }
        </div>
      </div>
    );
  }
};