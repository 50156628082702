import React from "react";
import { useAuthContext } from "../context/ApolloContext";
import { Badge } from "./Badge";

export const Banner = () => {
  const { user } = useAuthContext();
  const name = user.display_name;

  return (
    <div className="banner">
      <div className="content-container">
        <h3>Welcome {name}</h3>
        <p>Welcome to ConnectR! This application will support your lifelong Reconciliation journey. Start your journey or continue your learning progress by selecting a badge below.</p>
      </div>
      <Badge badge="sprout" status={{0: true, 1:true, 2: true, 3:true, 4: true, 5:true, 6: true, 7:true}} />
    </div>
  );
};
