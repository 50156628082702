import React from "react";
import PropTypes from "prop-types";

export const Badge = (props) => {
  const { badge, status, position, diameter } = props;

  const d = diameter || "100";

  const classes = [
    "badge",
    badge
  ];

  const colorLegend = {
    default: "#A9ACBA",
    indicator: "#101010",
    seed: "#0971CE",
    sprout: "#FDFDFD",
    grow: "#ED1C24",
    thrive: "#F0CC00",
  };

  const isComplete = () => {
    if (Object.keys(status).length === 8) {
      return Object.values(status).every(Boolean);
    } else {
      return false;
    }
  };

  const colors = {
    dots: isComplete() ? colorLegend[badge] : colorLegend.default,
    point1: status[0] ? colorLegend[badge] : position == 1 ? colorLegend.indicator : colorLegend.default,
    point2: status[1] ? colorLegend[badge] : position == 2 ? colorLegend.indicator : colorLegend.default,
    point3: status[2] ? colorLegend[badge] : position == 3 ? colorLegend.indicator : colorLegend.default,
    point4: status[3] ? colorLegend[badge] : position == 4 ? colorLegend.indicator : colorLegend.default,
    point5: status[4] ? colorLegend[badge] : position == 5 ? colorLegend.indicator : colorLegend.default,
    point6: status[5] ? colorLegend[badge] : position == 6 ? colorLegend.indicator : colorLegend.default,
    point7: status[6] ? colorLegend[badge] : position == 7 ? colorLegend.indicator : colorLegend.default,
    point8: status[7] ? colorLegend[badge] : position == 8 ? colorLegend.indicator : colorLegend.default,
  };

  return (
    <div className={classes.join(" ")}>
      <svg width={d} height={d} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* Dots */}
        <path d="M3 52.49C4.65685 52.49 6 51.1468 6 49.49C6 47.8331 4.65685 46.49 3 46.49C1.34315 46.49 0 47.8331 0 49.49C0 51.1468 1.34315 52.49 3 52.49Z" fill={colors.dots}/>
        <path d="M95.2402 52.49C96.8971 52.49 98.2402 51.1468 98.2402 49.49C98.2402 47.8331 96.8971 46.49 95.2402 46.49C93.5834 46.49 92.2402 47.8331 92.2402 49.49C92.2402 51.1468 93.5834 52.49 95.2402 52.49Z" fill={colors.dots}/>
        <path d="M48.96 99C50.6168 99 51.96 97.6569 51.96 96C51.96 94.3431 50.6168 93 48.96 93C47.3031 93 45.96 94.3431 45.96 96C45.96 97.6569 47.3031 99 48.96 99Z" fill={colors.dots}/>
        <path d="M48.96 6C50.6168 6 51.96 4.65685 51.96 3C51.96 1.34315 50.6168 0 48.96 0C47.3031 0 45.96 1.34315 45.96 3C45.96 4.65685 47.3031 6 48.96 6Z" fill={colors.dots}/>
        <path d="M16.4199 19.7C18.0768 19.7 19.4199 18.3569 19.4199 16.7C19.4199 15.0432 18.0768 13.7 16.4199 13.7C14.7631 13.7 13.4199 15.0432 13.4199 16.7C13.4199 18.3569 14.7631 19.7 16.4199 19.7Z" fill={colors.dots}/>
        <path d="M81.96 19.7C83.6168 19.7 84.96 18.3569 84.96 16.7C84.96 15.0432 83.6168 13.7 81.96 13.7C80.3031 13.7 78.96 15.0432 78.96 16.7C78.96 18.3569 80.3031 19.7 81.96 19.7Z" fill={colors.dots}/>
        <path d="M81.96 85.28C83.6168 85.28 84.96 83.9369 84.96 82.28C84.96 80.6232 83.6168 79.28 81.96 79.28C80.3031 79.28 78.96 80.6232 78.96 82.28C78.96 83.9369 80.3031 85.28 81.96 85.28Z" fill={colors.dots}/>
        <path d="M16.4199 85.28C18.0768 85.28 19.4199 83.9369 19.4199 82.28C19.4199 80.6232 18.0768 79.28 16.4199 79.28C14.7631 79.28 13.4199 80.6232 13.4199 82.28C13.4199 83.9369 14.7631 85.28 16.4199 85.28Z" fill={colors.dots}/>

        {/* Star */}
        <path d="M65.27 9V32.48L49.27 48.3V24.86L65.27 9Z" fill={colors.point1}/>
        <path d="M90.8598 34.8H67.3798L51.5698 50.8H75.0098L90.8598 34.8Z" fill={colors.point2}/>
        <path d="M51.5698 50.8H75.0498L90.8598 66.8H67.4198L51.5698 50.8Z" fill={colors.point3}/>
        <path d="M65.27 92.62V69.14L49.27 53.33V76.77L65.27 92.62Z" fill={colors.point4}/>
        <path d="M49.27 53.33V76.81L33.27 92.62V69.18L49.27 53.33Z" fill={colors.point5}/>
        <path d="M47.3 50.8H23.81L8 66.8H31.44L47.3 50.8Z" fill={colors.point6}/>
        <path d="M8 34.8H31.48L47.3 50.8H23.86L8 34.8Z" fill={colors.point7}/>
        <path d="M49.27 48.3V24.81L33.27 9V32.44L49.27 48.3Z" fill={colors.point8}/>
      </svg>
    </div>
  );
};

Badge.propTypes = {
  badge: PropTypes.string.isRequired,
  diameter: PropTypes.string,
  status: PropTypes.object,
  position: PropTypes.string,
};