import React, { useState } from "react";
import PropTypes from "prop-types";
import hand from "../assets/back-hand.svg";
import headphones from "../assets/headphones.svg";
import stories from "../assets/stories.svg";
import check from "../assets/check.svg";

export const ActionCard = (props) => {
  const { action, badge, callback, isSelected } = props;

  const [ selected, setSelected ] = useState(isSelected || false);

  const classes = [
    "action-card",
    badge,
    isSelected ? "selected" : ""
  ];

  const handleCardClick = () => {
    const status = selected;
    setSelected(!status);
    callback(action, !status, setSelected);
  };

  const actionType = action.tags.includes("read")
    ? "read"
    : action.tags.includes("listen") 
      ? "listen"
      : "engage";

  let iconSrc = "";
  let altText = "";
  switch (actionType) {
    case "read":
      iconSrc = stories;
      altText = "open book icon";
      break;
    case "listen":
      iconSrc = headphones;
      altText = "headphones icon";
      break;
    case "engage":
      iconSrc = hand;
      altText = "palm of hand icon";
      break;
  }

  return(
    <button className={classes.join(" ")} onClick={handleCardClick}>
      <div className="content-container">
        <span className="checkbox">
          <img className="checkmark" src={check} alt="white checkmark" />
        </span>
        <p className="action">{action.post.post_title}</p>
      </div>
      <img className="icon" src={iconSrc} alt={altText} />
    </button>
  );
};

ActionCard.propTypes = {
  action: PropTypes.object.isRequired,
  badge: PropTypes.string,
  callback: PropTypes.func,
  isSelected: PropTypes.bool
};