import React, { useState, useEffect } from "react";
import "../styles/index.scss";
import { useAuthContext } from "../context/ApolloContext";
import { useAuth } from "../hooks/auth";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { ActionCard } from "../components/ActionCard";
import { Button } from "../components/Button";
import { Spinner } from "../components/Spinner";

export const SelectActionsPage = () => {
  const { isLoggedIn } = useAuthContext();  
  if (!isLoggedIn) {
    return <Navigate to={"/login"} />;
  }

  const location = useLocation();
  const navigate = useNavigate();
  const badge = location.pathname.split('/')[1];
  const questionNum = location.state.questionNum;
  if (!questionNum) {
    return <Navigate to={"/"} />;
  }
  const { updateMetadata } = useAuth();
  const { metadata, questions, resources } = useAuthContext();
  const question = questions[badge][questionNum].question.post_content;
  const actions = resources[badge][questionNum];
  const key = badge + 'Activities_q' + questionNum;
  const userSelectedActions = metadata[key] || [];

  const [ numOfActions, setNumOfActions ] = useState(userSelectedActions.length || 0);
  const [ selectedActions, setSelectedActions ] = useState(userSelectedActions);
  const [ filteredActions, setFilteredActions ] = useState([]);
  const [ actionFilter, setActionFilter ] = useState("all");
  const [ loading, setLoading ] = useState(false);

  const handleSelectedAction = (action, selected, setSelected) => {
    const actions = selectedActions;

    if (selected) {
      if (actions.length < 3 && !actions.some(selectedAction => selectedAction[0].post.ID == action.post.ID)) {
        actions.push([action, '']);
      } else {
        setSelected(false);
        alert("You can only select 3 actions.\nPlease unselect any selected actions to select a new action.");
      }
    } else {
      let actionIndex = -1;
      for (let index = 0; index < actions.length; index++) {
        if (actions[index][0].post.ID === action.post.ID) {
          actionIndex = index;
          break;
        }
      }
      if (actionIndex > -1 && actions[actionIndex][1] !== "") {
        alert("This action has already been completed and cannot be unselected.");
      } else {
        actions.splice(actionIndex, 1);
      }
    }
    setSelectedActions(actions);
    setNumOfActions(actions.length);
  };

  const setFilteredActionsData = () => {
    let filteredActionsData = [];
    switch (actionFilter) {
      case "read":
        filteredActionsData = actions.read;
        break;
      case "listen":
        filteredActionsData = actions.listen;
        break;
      case "engage":
        filteredActionsData = actions.engage;
        break;
      case "all":
        filteredActionsData = [
          ...actions.read,
          ...actions.listen,
          ...actions.engage
        ];
        break;
    }

    setFilteredActions(filteredActionsData);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (selectedActions.length === 3) {
      const key = badge + "Activities_q" + questionNum;
      const userSelectedActions = selectedActions;
      await updateMetadata(key, userSelectedActions, "learn-progress");
      navigate("/" + badge + "/actions", {
        state: {
          questionNum: questionNum,
        }
      });
    } else {
      alert("You must select 3 actions before proceeding.");
    }
    setLoading(false);
  };

  const actionCards = filteredActions.map((action) => {
    const selected = selectedActions.some(selectedAction => selectedAction[0].post.ID == action.post.ID);
    
    return (
      <ActionCard
        key={action.post.ID}
        action={action}
        badge={badge}
        callback={handleSelectedAction}
        isSelected={selected}
      />
    );
  });

  useEffect(() => {
    setFilteredActionsData();
  }, [actionFilter]);

  return (
    <div className="select-actions-page">
      <h3>{question}</h3>
      <div className="action-options">
        {!!loading && <Spinner loading />}
        <p>{"*Select Your Actions ("+ numOfActions +"/3)"}</p>
        <div className="action-filters">
          <Button 
            type="secondary"
            onclick={() => setActionFilter("all")}
            text="View All"
            extraClasses={actionFilter === "all" ? "active" : ""}
          />
          <Button 
            type="icon"
            onclick={() => setActionFilter("read")}
            text="Read"
            icon="read"
            extraClasses={actionFilter === "read" ? "active" : ""}
          />
          <Button 
            type="icon"
            onclick={() => setActionFilter("listen")}
            text="Listen"
            icon="listen"
            extraClasses={actionFilter === "listen" ? "active" : ""}
          />
          <Button 
            type="icon"
            onclick={() => setActionFilter("engage")}
            text="Engage"
            icon="engage"
            extraClasses={actionFilter === "engage" ? "active" : ""}
          />
        </div>
      </div>
      <div className="action-list">
        {actionCards}
      </div>
      <Button 
        type="next"
        onclick={() => handleSubmit()}
        text="Next"
      />
    </div>
  );
};
