import React, { useEffect, useState } from "react";
import "../styles/index.scss";
import { useAuth } from "../hooks/auth";
import { useAuthContext } from "../context/ApolloContext";
import { Navigate, useLocation, Link } from "react-router-dom";
import { FullActionCard } from "../components/FullActionCard";
import { Button } from "../components/Button";
import { Modal } from "../components/Modal";
import { Spinner } from "../components/Spinner";

//TODO: Add confirmation alert when leaving page if user has added text to the reflection sections
export const ActionsPage = () => {
  const { isLoggedIn } = useAuthContext();
  if (!isLoggedIn) {
    return <Navigate to={"/login"} />;
  }

  const { updateMetadata } = useAuth();
  const { metadata, questions } = useAuthContext();
  const { pathname, state } = useLocation();
  const badge = pathname.split('/')[1];
  const questionNum = state?.questionNum;
  if (!questionNum) {
    return <Navigate to={"/"} />;
  }
  const question = questions[badge][questionNum].question.post_content;
  const reflectionQ1 = questions[badge][questionNum].reflection_1;
  const reflectionQ2 = questions[badge][questionNum].reflection_2;
  const actionKey = badge + 'Activities_q' + questionNum;
  const actions = metadata[actionKey];
  const reflectionKey = badge + 'Reflections_q' + questionNum;
  const userReflections = metadata[reflectionKey];
  const userReflectionQ1 = userReflections.length > 0 ? userReflections[0].a : "";
  const userReflectionQ2 = userReflections.length === 2 ? userReflections[1].a : "";
  const completionKey = badge + 'BadgeCompletion';
  const questionCompleted = metadata[completionKey][questionNum-1];

  const [ reflectQ1, setReflectQ1 ] = useState(userReflectionQ1);
  const [ reflectQ2, setReflectQ2 ] = useState(userReflectionQ2);
  const [ disableSubmit, setDisableSubmit ] = useState(true);
  const [ modalShow, setModalShow ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const handleCompleteAction = (action) => {
    const date = new Date();
    const completedDate = date.getFullYear() + '-' + parseInt(date.getMonth() + 1) + '-' + date.getDate();

    const updateActions = actions;
    updateActions.map(userSelectedAction => {
      if (userSelectedAction[0].post.ID === action.post.ID) {
        userSelectedAction.pop();
        userSelectedAction.push(completedDate);
      }
    });

    updateMetadata(actionKey, updateActions, "learn-progress");
  };

  const fullActionCards = actions.map((action, index) => {
    const post = action[0];
    let completed = false;
    actions.forEach(selectedAction => {
      if (selectedAction[0].ID === post.ID && action[1] !== "") {
        completed = true;
      }
    });

    return (      
      <FullActionCard
        key={index}
        action={post}
        badge={badge}
        completed={completed}
        callback={handleCompleteAction}
      />
    );
  });

  const allActionsCompleted = () => {
    let completed = false;
    actions.forEach(action => {
      if (action[1] !== "") {
        completed = true;
      } else {
        completed = false;
      }
    });

    return completed;
  };

  const reflectionClasses = [
    "reflections",
    !allActionsCompleted() || questionCompleted ? "disable" : ""
  ];

  const handleSubmit = async () => {
    const date = new Date();
    const completedDate = date.getFullYear() + '-' + parseInt(date.getMonth() + 1) + '-' + date.getDate();

    const reflections = [
      {q: reflectionQ1, a: reflectQ1, date: completedDate},
      {q: reflectionQ2, a: reflectQ2, date: completedDate}
    ];
    
    setLoading(true);
    await updateMetadata(reflectionKey, reflections, "badges");
    
    const completionStatus = metadata[completionKey];
    completionStatus[questionNum-1] = true;
    await updateMetadata(completionKey, completionStatus, "badges");
    setLoading(false);
    
    setModalShow(true);
  };

  const disableReflections = () => {
    if (questionCompleted) {
      return true;
    } else if (allActionsCompleted()) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (!actions.length) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [actions]);
  

  useEffect(() => {
    if (questionCompleted) {
      setDisableSubmit(true);
      if (reflectionQ2 !== "") {
        if (reflectQ1 == "" && reflectQ2 == "") {
          setLoading(true);
        } else {
          setLoading(false);
        }
      } else if (reflectQ1 == "") {
        setLoading(true);
      } else {
        setLoading(false);
      }
    } else if (reflectionQ2 !== "") {
      if (reflectQ1 !== "" && reflectQ2 !== "") {
        setDisableSubmit(false);
      }
    } else if (reflectQ1 !== "") {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [reflectQ1 && reflectQ2]);

  const setReflection = (value, num) => {
    if (num === 1) {
      setReflectQ1(value);
    } else if (num ===2) {
      setReflectQ2(value);
    }
    const a1Length = reflectQ1.length;
    const a2Length = reflectQ2.length;
    if (!value.length) {
      setDisableSubmit(true);
    }
    else if (reflectionQ2.length) {
      if (a1Length && a2Length) {
        setDisableSubmit(false);
      } else {
        setDisableSubmit(true);  
      }
    } else if (a1Length) {
      setDisableSubmit(false);
    }
  };

  return (
    <div className="actions-page">
      {modalShow && <Modal badge={badge} questionNum={questionNum} /> }
      <div className="header-container">
        <h3>{question}</h3>
        {!questionCompleted && 
          <Link 
            to={"/" + badge + "/select-actions"}
            state={{questionNum: questionNum}}
            className="edit-actions"
          >
            Edit Actions
          </Link>
        }
      </div>
      { !!loading && <Spinner loading /> }
      <div className="action-list">
        { fullActionCards }
      </div>
      <div className={reflectionClasses.join(" ")}>
        <div className="question-1">
          <h3>{reflectionQ1}</h3>
          <textarea value={reflectQ1} onChange={event => setReflection(event.target.value, 1)} disabled={disableReflections()}/>
        </div>
        {reflectionQ2 !== "" && (
          <div className="question-2">
            <h3>{reflectionQ2}</h3>
            <textarea value={reflectQ2} onChange={event => setReflection(event.target.value, 2)} disabled={disableReflections()}/>
          </div>
        )}
      </div>
      <Button 
        type="primary"
        text="Submit"
        onclick={handleSubmit}
        extraClasses="submit"
        disabled={disableSubmit}
      />
    </div>
  );
};
