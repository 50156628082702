import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { SideBar } from "../components/SideBar";
import { useAuthContext } from "../context/ApolloContext";
import { api } from "../util/axios";
import { Spinner } from "../components/Spinner";
import "../styles/index.scss";

export const AdminPage = () => {
  const { isLoggedIn, groupData } = useAuthContext();
  const [ users, setUsers ] = useState([]);
  const [ requests, setRequests ] = useState([]);
  const [ numberOfUsers, setNumberOfUsers ] = useState(null);
  const [ teams, setTeams ] = useState([]);
  const [ newTeamName, setNewTeamName ] = useState("");
  const [ loading, setLoading ] = useState(true);
  const planID = groupData[0].plan_id;

  if (!isLoggedIn) {
    return <Navigate to={"/login"} />;
  }

  useEffect(() => {
    getAdminPageData();
    getTeamList();
  }, []);

  useEffect(() => {
    if (users) {
      setNumberOfUsers(users.length);
    }
  }, [users]);

  const handleDelete = async (id, display_name) => {
    const confirm = window.confirm(`Do you want to remove ${display_name}?`);
    if (!confirm) {
      return;
    };
    await api.post("https://beaconnectr.org/wp-json/api/v1/user-delete", {
      user_id: id
    }).then(() => {
      getAdminPageData();
      getTeamList();
      window.alert(`${display_name} has been removed from your team`);
    });
  };

  const handleUpdateTeam = (id=null, options=null) => {  
    const body = {
      user_id: id,
      meta_data_key: "team_name",
      meta_data_value: options,
    };
    const requestArr = [...requests];
    const newRequest = {
      body,
    };
    requestArr.push(newRequest);
    setRequests(requestArr);
  };

  const submit = async () => {
    setLoading(true);
    const promises = requests.map((request) => {
      return api.post("https://beaconnectr.org/wp-json/api/v1/user/membership/id", request.body);
    });
    await Promise.all(promises).then(() => {
      getAdminPageData();
      getTeamList();
    }).catch((err) => {
      console.warn(err);
    }).finally(() => {
      setLoading(false);
    });
  };

  const getAdminPageData = async () => {
    await api.post("https://beaconnectr.org/wp-json/api/v1/membership/users", {
      plan_id: planID,
    }).then((res) => {
      setUsers(res.data.data.plan_users);
      setLoading(false);
    }).catch((err) => {
      console.warn(err);
    });
  };

  const getTeamList = async () => {
    await api.post("https://beaconnectr.org/wp-json/api/v1/plan-membership/teams", {
      plan_id: planID,
    }).then((res) => {
      const json = JSON.parse(res.data.data.teams)[0];
      let teams = [];
      if (json && json?.length) {
        json.forEach((item) => {
          teams.push(item.team_name);
        });
      }
      teams.push("N/A");
      setTeams(teams);
    }).catch((err) => {
      console.warn(err);
    });
  };

  const renderTeamOptions = (team) => {
    if (team) {
      return teams.map((teamName, i) => {
        if (teamName == team) {
          return <option key={i} selected="selected">{teamName}</option>;
        } else {
          return <option key={i}>{teamName}</option>;
        }
      });
    } else {
      return teams.map((teamName, i) => {
        if (teams.length === i + 1) {
          return <option key={i} selected="selected">{teamName}</option>;
        } else {
          return <option key={i}>{teamName}</option>;
        }
      });
    }
  };

  const renderUserList = () => {
    return users.map((user, i) => {
      const team = user.team;
      const id = user.ID;
      return (
        <tr key={i}>
          <td>{user.display_name}</td>
          <td>{user.user_email}</td>
          <td>{user.role}</td>
          <td>
          <select name="newTeamName" id="newTeamName" onChange={(e) => handleUpdateTeam(id, e.target.value)}>
            {renderTeamOptions(team)}
          </select>
          </td>
          <td className="admin-remove-button" onClick={() => handleDelete(user.ID, user.display_name)}>{"Remove"}</td>
        </tr>
      );
    });
  };

  const handleChangeNewTeamName = (e) => {
    setNewTeamName(e.target.value);
  };

  const addNewTeam = async (e) => {
    e.preventDefault();
    if (!newTeamName.length) {
      return;
    }
    await api.post("https://beaconnectr.org/wp-json/api/v1/update-plan-membership", {
      action: "add_team",
      plan_id: planID,
      team_name: newTeamName
    }).then(async () => {
      await getTeamList();
      setNewTeamName("");
    }).catch((err) => {
      console.warn(err);
    });
  };


  return (
    <div className="page">
      <div className="AdminPage">
        <SideBar tabIndex={3} extraClasses="onPage" />
        <div className="admin-page-content">
          <p className="title">Admin Page</p>
          {loading ? <Spinner loading /> : (
            <>
            {!!numberOfUsers && <h4>Total Users: {numberOfUsers}</h4>}
              <table className="admin">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Team</th>
                  </tr>
                </thead>
                <tbody>
                  {renderUserList()}
                </tbody>
                {!!requests.length && <button className="admin-submit" onClick={submit}>Update</button>}
              </table>
              <form className="admin-add-team-form" onSubmit={addNewTeam}>
                <label>Add a New Team</label>
                <div className="admin-input-container">
                  <input type="text" onChange={(e) => handleChangeNewTeamName(e)} value={newTeamName}/>
                  <input type="submit" />
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
