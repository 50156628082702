import React, { useEffect, useState }from "react";
import { Navigate } from "react-router-dom";
import { SideBar } from "../components/SideBar";
import { useAuthContext } from "../context/ApolloContext";
import { api } from "../util/axios";
import "../styles/index.scss";
import { TeamBadgeCard } from "../components/TeamBadgeCard";
import { Spinner } from "../components/Spinner";

export const TeamPage = () => {
  const [teamData, setTeamData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isLoggedIn, groupData, user } = useAuthContext();
  const userID = user.ID;
  const planID = groupData[0]?.plan_id;

  if (!isLoggedIn) {
    return <Navigate to={"/login"} />;
  }

  if (!planID) {
    console.warn("Missing Plan ID");
    return <Navigate to={"/"} />;
  }

  useEffect(() => {
    getTeamBadgeData();
  }, []);

  const getTeamBadgeData = async () => {
    let teamID = "";
    await api.post("https://beaconnectr.org/wp-json/api/v1/membership/users", {
      plan_id: planID,
    }).then(async (res) => {
      const currentUser = res.data.data.plan_users.filter((planUser) => {
        return planUser.ID == userID;
      });
      if (currentUser) {
        teamID = currentUser[0].team;
      }
      await api.post('https://beaconnectr.org/wp-json/api/v1/membership/team/badges', {
        plan_id: planID,
        team_id: teamID,
      }).then((res) => {
        const team = res.data.data.plan_users.filter((teamUser) => {
          return teamUser.ID != userID;
        });
        setTeamData(team);
        setLoading(false);
      });
    });
  };

  const renderTeamBadges = () => {
    if (!teamData.length) {
      return;
    }
    return teamData.map((data, i) => {
      const {
        seedBadgeCompletion,
        growBadgeCompletion,
        sproutBadgeCompletion,
        thriveBadgeCompletion,
        display_name,
      } = data;
      const badgeCompletion = {
        seed: {},
        sprout: {},
        grow: {},
        thrive: {},
      };
      if (seedBadgeCompletion.length !== 0) {
        for (let index = 0; index < 8; index++) {
          badgeCompletion.seed[index] = seedBadgeCompletion[index];
        }
      }
      if (growBadgeCompletion.length !== 0) {
        for (let index = 0; index < 8; index++) {
          badgeCompletion.grow[index] = growBadgeCompletion[index];
        }
      }
      if (sproutBadgeCompletion.length !== 0) {
        for (let index = 0; index < 8; index++) {
          badgeCompletion.sprout[index] = sproutBadgeCompletion[index];
        }
      }
      if (thriveBadgeCompletion.length !== 0) {
        for (let index = 0; index < 8; index++) {
          badgeCompletion.thrive[index] = thriveBadgeCompletion[index];
        }
      }
      return (
        <TeamBadgeCard
          key={i}
          name={display_name}
          seedBadgeCompletion={badgeCompletion.seed}
          growBadgeCompletion={badgeCompletion.grow}
          sproutBadgeCompletion={badgeCompletion.sprout}
          thriveBadgeCompletion={badgeCompletion.thrive}
        />
      );
    });
  };

  if (loading) {
    return (
      <div className="page">
        <div className="team-page">
          <SideBar tabIndex={1} extraClasses="onPage" />
          <Spinner loading />
        </div>
      </div>
    );
  } else {
    return (
      <div className="page">
        <div className="team-page">
          <SideBar tabIndex={1} extraClasses="onPage" />
          <div className="team-page-content">
            <h3 className="title">Your Team {!!teamData?.length && `(${teamData?.length})`}</h3>
            <div className="team-badge-container">
              {renderTeamBadges()}
            </div>
          </div>
        </div>
      </div>
    );
  }
};
