/**
 * External dependencies
 */
import React, { createContext, useContext } from 'react';

/**
* Internal dependencies
*/
import { useLocalStorage } from '../hooks/localStorage';

const AuthContext = createContext( {
  isLoggedIn: false,
  setIsLoggedIn: () => null,
  user: null,
  setUser: () => null,
  groupData: null,
  setGroupData: () => null,
  questions: null,
  setQuestions: () => null,
  metadata: null,
  setMetadata: () => null,
  resources: null,
  setResources: () => null,
} );

export const useAuthContext = () => {
  return useContext( AuthContext );
};

// eslint-disable-next-line react/prop-types
export const AuthContextProvider = ( { children } ) => {
  const [ isLoggedIn, setIsLoggedIn ] = useLocalStorage(false);
  const [ user, setUser ] = useLocalStorage( "connectRUser", "" );
  const [ groupData, setGroupData ] = useLocalStorage( "connectRGroupData", "");
  const [ questions, setQuestions ] = useLocalStorage( "connectRQuestions", "");
  const [ metadata, setMetadata ] = useLocalStorage( "connectRUserMetadata", "");
  const [ resources, setResources ] = useLocalStorage( "connectRResources", "");

  const contextValue = {
    isLoggedIn,
    setIsLoggedIn,
    user,
    setUser,
    groupData,
    setGroupData,
    questions,
    setQuestions,
    metadata,
    setMetadata,
    resources,
    setResources
  };

  return (
    <AuthContext.Provider value={ contextValue }>
      { children }
    </AuthContext.Provider>
  );
};