import React from "react";
import { useAuthContext } from "../context/ApolloContext";
import { Navigate } from "react-router-dom";

export const NotFound = () => {
  const { isLoggedIn } = useAuthContext();  
  if (!isLoggedIn) {
    return <Navigate to={"/login"} />;
  } else {
    return <Navigate to={"/"} />;
  }
};
