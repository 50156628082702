import React, {useState, useEffect, useRef} from "react";
import logo from "../assets/ctr-logo.png";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from "react-router-dom";
import { SideBar } from "./SideBar";
import { Link } from "react-router-dom";

export const NavBar = () => {

  const [open, setOpen] = useState(false);
  const path = useLocation().pathname;
  let renderSidebar = false;
  let tabIndex = 0;

  switch (path) {
    case '/':
      renderSidebar = true;
      tabIndex = 0;
      break;
    case '/settings':
      renderSidebar = true;
      tabIndex = 2;
      break;
    case '/team':
      renderSidebar = true;
      tabIndex = 1;
      break;
    case '/admin':
      renderSidebar = true;
      tabIndex = 3;
      break;
  }

  const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
      const listener = event => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener('mousedown', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
      };
    },
    [ref, handler],
   );
  };

  const node = useRef(); 
  useOnClickOutside(node, () => setOpen(false));

  //TODO: Add Admin check here for SideBar

  if (path === "/login" || path === "/signup") {
    return;
  } else {
    return (
      <div className="navigation">
        <Link to={"/"}><img className="logo" src={logo}/></Link>
        {renderSidebar && (
          <div ref={node}>
            <button className="menu-button" onClick={() => setOpen(!open)}>{open ? <CloseIcon fontSize="large" /> : <MenuIcon fontSize="large" />}</button>
            <SideBar tabIndex={tabIndex} extraClasses={open ? "mobile open" : "mobile"} />
          </div>
        )}
      </div>
    );
  }
};