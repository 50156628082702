import React from "react";
import { Link } from "react-router-dom";
import { Badge } from "./Badge";
import PropTypes from "prop-types";
import checkmark from "../assets/done-checkmarks.svg";
import arrow from "../assets/next-arrow.svg";
import { useAuthContext } from '../context/ApolloContext';

export const QuestionCard = (props) => {
  const { badge, questionNumber, question, completed } = props;
  const { metadata } = useAuthContext();

  const link = metadata[badge + "Activities_q" + questionNumber].length !== 0 ? "actions" : "select-actions";

  const classes = [
    "question-card",
    badge,
    completed ? "complete" : ""
  ];

  const status = {};
  status[questionNumber-1] = completed;

  return(
    <Link to={link} state={{questionNum: questionNumber}} className={classes.join(" ")}>
      <div className="content-container">
        <Badge badge={badge} status={status} position={questionNumber}/>
        <p className="question">{question}</p>
        {completed 
          ? (<img className="status" src={checkmark} alt="two overlapped checkmarks" />)
          : (<img className="status" src={arrow} alt="arrow pointing right" />)
        }
      </div>
    </Link>
  );
};

QuestionCard.propTypes = {
  badge: PropTypes.string.isRequired,
  questionNumber: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  completed: PropTypes.bool,
};