import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ActionsPage } from "./pages/ActionsPage";
import { FrontPage } from "./pages/FrontPage";
import { LoginPage } from "./pages/LoginPage";
import { NotFound } from "./pages/NotFoundPage";
import { SignupPage } from "./pages/SignupPage";
import { SelectQuestionPage } from "./pages/SelectQuestionPage";
import { SelectActionsPage } from "./pages/SelectActionsPage";
import { SettingsPage } from "./pages/SettingsPage";
import { useApolloClient } from "./hooks/apolloClient";
import { AuthContextProvider } from "./context/ApolloContext";
import { ApolloProvider } from "@apollo/client";
import { TeamPage } from "./pages/TeamPage";
import { AdminPage } from "./pages/AdminPage";
import { NavBar } from "./components/NavBar";

function App() {
  const client = useApolloClient();

  return (
    <AuthContextProvider>
      <ApolloProvider client={client}>
        <Router>
          <NavBar />
          <Routes>
            <Route path="/" element={<FrontPage />} exact />
            <Route path="seed" element={<SelectQuestionPage />}>
              <Route path="select-actions" element={<SelectActionsPage />}/>
              <Route path="actions" element={<ActionsPage />}/>
            </Route>
            <Route path="sprout" element={<SelectQuestionPage />}>
              <Route path="select-actions" element={<SelectActionsPage />}/>
              <Route path="actions" element={<ActionsPage />}/>
            </Route>
            <Route path="grow" element={<SelectQuestionPage />}>
              <Route path="select-actions" element={<SelectActionsPage />}/>
              <Route path="actions" element={<ActionsPage />}/>
            </Route>
            <Route path="thrive" element={<SelectQuestionPage />}>
              <Route path="select-actions" element={<SelectActionsPage />}/>
              <Route path="actions" element={<ActionsPage />}/>
            </Route>
            <Route path="team" exact element={<TeamPage />} />
            <Route path="admin" exact element={<AdminPage />} />
            <Route path="login" exact element={<LoginPage />} />
            <Route path="signup" exact element={<SignupPage />} />
            <Route path="settings" exact element={<SettingsPage />} />
            <Route path="*" element={<NotFound />}/>
          </Routes>
        </Router>
      </ApolloProvider>
    </AuthContextProvider>
  );
}

export default App;
