import React from "react";
import { Link } from "react-router-dom";
import { Badge } from "./Badge";
import PropTypes from "prop-types";
import checkmark from "../assets/done-checkmarks.svg";
import arrow from "../assets/next-arrow.svg";

export const BadgeCard = (props) => {
  const { badge, description, status } = props;

  const classes = [
    "badge-card",
    badge
  ];

  const isComplete = () => {
    return Object.values(status).every(Boolean);
  };

  return (
    <Link to={badge} className={classes.join(" ")}>
      <div className="content-container">
        <div className="heading-container">
          <h2>{badge}</h2>
          <Badge
            badge={badge}
            status={status}
          />
        </div>
        <div className="bottom-container">
          <p>{description}</p>
          {isComplete()
            ? (<img className="status" src={checkmark} alt="two overlapped checkmarks" />)
            : (<img className="status" src={arrow} alt="arrow pointing right" />)
          }
        </div>
      </div>
    </Link>
  );
};

BadgeCard.propTypes = {
  badge: PropTypes.string.isRequired,
  description: PropTypes.string,
  status: PropTypes.object.isRequired,
};