import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";

const link = createHttpLink({
  uri: "https://beaconnectr.org/graphql",
  credentials: 'include',
});

//TODO: uri should be from .env

export const useApolloClient = () => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link
  });
};
