/**
 * External dependencies
*/
import { useState } from 'react';
import { api } from '../util/axios';
// import { decodeEntities } from '@wordpress/html-entities';
// import { stripHtml } from 'string-strip-html';

/**
* Internal dependencies
*/
import { useAuthContext } from '../context/ApolloContext';
import { useSafeDispatch } from './safe-dispatch';

/**
* Hook which tracks if the user is logged in.
*/
export const useAuth = () => {
  const { isLoggedIn, setIsLoggedIn, setUser, setGroupData, setQuestions, setMetadata, setResources } = useAuthContext();
  const [ error, setError ] = useState({isError: false, message: ""});
  const [ status, setStatus ] = useState( 'idle' );

  const onLoginSuccess = useSafeDispatch( (user) => {
    setIsLoggedIn( true );
    const data = user.data.data;
    const userData = {
      ID: data.ID,
      display_name: data.display_name,
      user_email: data.user_email,
      user_login: data.user_login,
      user_nicename: data.user_nicename,
    };
    setUser(userData);
    setStatus( 'success' );
  } );

  const onLogoutSuccess = useSafeDispatch( () => {
    setIsLoggedIn( false );
    setUser(null);
    setStatus( 'resolved' );
    return true;
  } );

  const onCreateSuccess = useSafeDispatch( () => {
    setStatus( 'success' );
    return true;
  } );

  const onError = useSafeDispatch( ( errors ) => {
    console.log(errors);
    return false;
  } );

  const getGroupData = () => {
    api.post('https://beaconnectr.org/wp-json/api/v1/get-user-memberships').then((data) => {
      const groupData = data.data.data;
      const filteredGroupData = groupData.filter((group) => {
        return group.status == "wcm-active";
      });
      setGroupData(filteredGroupData);
    });
  };

  const login = ( username, password ) => {
    setError({isError: false, message: ""});
    setStatus( 'resolving' );
    api.post('https://beaconnectr.org/wp-json/api/v1/login', {
      user_login: username,
      user_password: password
    }).then((data) => {
      if (data.data.status === 400) {
        const wpErrors = data.data.data.errors;
        if (Object.keys(wpErrors).length !== 0) {
          const errorKey = Object.keys(wpErrors)[0];
          setError({isError: true, message: wpErrors[errorKey][0]});
          setStatus( 'resolved' );
        }
      } else {
        getGroupData();
        onLoginSuccess(data.data);
      }
    }).catch( onError );
  };

  const logout = async () => {
    setStatus( 'resolving' );
    await api.post('https://beaconnectr.org/wp-json/api/v1/logout')
      .then(() => {
        onLogoutSuccess();
      })
      .catch(onError);
  };

  const createUser =  async ( userData ) => {
    setError({isError: false, message: ""});
    setStatus( 'resolving' );
    api.post('https://beaconnectr.org/wp-json/api/v1/user', {
      user_login: userData.email,
      user_password: userData.password,
      first_name: userData.firstName,
      last_name: userData.lastName,
    }).then((data) => {
      if (data.data.status === 400) {
        setError({isError: true, message: data.data.response});
        setStatus( 'resolved' );
      } else { 
        api.post('https://beaconnectr.org/wp-json/api/v1/user-membership', {
          user_id: data.data.data.id,
          plan_id: userData.planID,
          role: "learner",
        }).then((response) => {
          if (response.data.status === 400) {
            setError({isError: true, message: response.data.response});
            setStatus( 'resolved' );
          } else {
            onCreateSuccess();
          }
        }).catch(onError);
      }
    }).catch(onError);
  };

  const getQuestions = async () => {
    await api.post('https://beaconnectr.org/wp-json/api/v1/get-questions').then((data) => {
      const questionsArr = data.data.posts;
      const questionsObj = {
        seed: {},
        sprout: {},
        grow: {},
        thrive: {}
      };
      questionsArr.forEach((question) => {
        const questionBadge = question.post.post_name.split("-")[0];
        const questionNumber = question.post.post_name.slice(-1);
        questionsObj[questionBadge][questionNumber] = {};
        questionsObj[questionBadge][questionNumber]['question'] = question.post;
        questionsObj[questionBadge][questionNumber]['reflection_1'] = question.reflection_1[0];
        questionsObj[questionBadge][questionNumber]['reflection_2'] = question.reflection_2[0];
      });
      setQuestions(questionsObj);
    }).catch((err) => {
      console.log(err);
    });
  };

  const getMetadata = async () => {
    await api.post('https://beaconnectr.org/wp-json/api/v1/user-meta').then((data) => {
      const allMetadata = data.data.data;
      const metadata = {};
      const badges = ['seed', 'sprout', 'grow', 'thrive'];

      badges.forEach(key => {
        const defaultCompletion = {
          0: false,
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
          6: false,
          7: false
        };

        const completionKey = key + "BadgeCompletion";
        let completion = [];
        if (allMetadata) {
          completion = allMetadata[completionKey];
        }
        const completionObject = {};

        if (completion.length !== 0) {
          for (let index = 0; index < 8; index++) {
            completionObject[index] = completion[0][index];
          }

          metadata[completionKey] = completionObject;
        } else {
          metadata[completionKey] = defaultCompletion;
        }

        for (let qNum = 1; qNum < 9; qNum++) {
          const activitiesKey = key + "Activities_q" + qNum;
          const reflectionsKey = key + "Reflections_q" + qNum;
          
          let activities = [];
          let reflection = [];
          if (allMetadata) {
            activities = allMetadata[activitiesKey];
            reflection = allMetadata[reflectionsKey];
          }

          metadata[activitiesKey] = activities.length !== 0 ? activities[0] : activities;
          metadata[reflectionsKey] = reflection.length !== 0 ? reflection[0] : reflection;
        }
      });

      setMetadata(metadata);
    });
  };

  const updateMetadata = async (key, data, update_key) => {
    await api.post('https://beaconnectr.org/wp-json/api/v1/update-user-meta', {
      update_key: update_key,
      meta_key: key,
      meta_value: data,
    }).then(async () => {
      await getMetadata();
    });
  };

  const getResources = async () => {
    const badges = ['seed', 'sprout', 'grow', 'thrive'];
    await api.post('https://beaconnectr.org/wp-json/api/v1/get-resources', {
      tags: badges
    }).then((data) => {
      const posts = data.data.posts;

      const sortedPosts = {
        seed: {},
        sprout: {},
        grow: {},
        thrive: {}
      };

      const badgePosts = {
        seed: posts.filter(post => post.tags.includes(badges[0])),
        sprout: posts.filter(post => post.tags.includes(badges[1])),
        grow: posts.filter(post => post.tags.includes(badges[2])),
        thrive: posts.filter(post => post.tags.includes(badges[3])),
      };

      Object.keys(badgePosts).forEach(badge => {
        for (let qNum = 1; qNum < 9; qNum++) {
          sortedPosts[badge][qNum] = {
            read: badgePosts[badge].filter(post => post.tags.includes('read') && post.tags.includes('question-' + qNum)),
            listen: badgePosts[badge].filter(post => post.tags.includes('listen') && post.tags.includes('question-' + qNum)),
            engage: badgePosts[badge].filter(post => post.tags.includes('engage') && post.tags.includes('question-' + qNum)),
          };          
        }
      });

      setResources(sortedPosts);
    });
  };

  return {
    login,
    logout,
    createUser,
    isLoggedIn,
    error,
    status,
    getQuestions,
    getMetadata,
    updateMetadata,
    getResources
  };
};
