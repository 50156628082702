//Next button - next with arrow
//Primary - blue white text
//Secondary - grey text no background, white background when active
//Icon - read, listen, engage, edit, completed

import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import arrow from "../assets/next-arrow.svg";
import hand from "../assets/back-hand.svg";
import headphones from "../assets/headphones.svg";
import stories from "../assets/stories.svg";

export const Button = (props) => {
  const { type, text, onclick, link, icon, extraClasses, disabled } = props;

  const classes = [
    "btn",
    type,
    extraClasses
  ];

  let iconSrc = "";
  let altText = "";
  if (icon) {
    switch (icon) {
      case "read":
        iconSrc = stories;
        altText = "open book icon";
        break;
      case "listen":
        iconSrc = headphones;
        altText = "headphones icon";
        break;
      case "engage":
        iconSrc = hand;
        altText = "palm of hand icon";
        break;
    } 
  }

  switch (type) {
    case "primary":
      if (onclick) {
        return (
          <button className={classes.join(" ")} onClick={onclick} disabled={disabled}>
            {text}
          </button>
        );
      } else {
        return (
          <Link to={link} className={classes.join(" ")}>
            {text}
          </Link>
        );
      }
    case "secondary":
      if (onclick) {
        return (
          <button className={classes.join(" ")} onClick={onclick} disabled={disabled}>
            {text}
          </button>
        );
      } else {
        return (
          <Link to={link} className={classes.join(" ")}>
            {text}
          </Link>
        );
      }
    case "icon":
      if (onclick) {
        return (
          <button className={classes.join(" ")} onClick={onclick} disabled={disabled}>
            <img className="icon" src={iconSrc} alt={altText} />
            {text}
          </button>
        );
      } else {
        return (
          <Link to={link} className={classes.join(" ")}>
            <img className="icon" src={iconSrc} alt={altText} />
            {text}
          </Link>
        );
      }
    case "next":
      if (onclick) {
        return (
          <button className={classes.join(" ")} onClick={onclick}>
            {text}
            <img className="arrow" src={arrow} alt="arrow pointing right" disabled={disabled}/>
          </button>
        );
      } else {
        return (
          <Link to={link} className={classes.join(" ")}>
            {text}
            <img className="arrow" src={arrow} alt="arrow pointing right" />
          </Link>
        );
      }
  }
};

Button.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string,
  icon: PropTypes.string,
  onclick: PropTypes.func,
  link: PropTypes.string,
  extraClasses: PropTypes.string,
  disabled: PropTypes.bool
};
