import React, { useState } from "react";
import PropTypes from "prop-types";
import hand from "../assets/back-hand.svg";
import headphones from "../assets/headphones.svg";
import stories from "../assets/stories.svg";
import checkmark from "../assets/done-checkmarks-wide.svg";
import DOMPurify from "dompurify";
import parse from "html-react-parser";

export const FullActionCard = (props) => {
  const { action, badge, completed, callback } = props;

  const [ isComplete, setIsComplete ] = useState(completed || false);

  const image = action.image !== '' ? action.image.replace("\\", "").match(/src="(.*?)"/)[1] : '';
  const title = action.post.post_title;
  const content = parse(DOMPurify.sanitize(action.post.post_excerpt.replace("\\", "")));

  const classes = [
    "full-action-card",
    badge,
    isComplete ? "complete" : "",
  ];

  const handleCompleteClick = () => {
    setIsComplete(true);
    callback(action);
  };

  const actionType = action.tags.includes("read")
    ? "read"
    : action.tags.includes("listen") 
      ? "listen"
      : "engage";

  let iconSrc = "";
  let altText = "";
  switch (actionType) {
    case "read":
      iconSrc = stories;
      altText = "open book icon";
      break;
    case "listen":
      iconSrc = headphones;
      altText = "headphones icon";
      break;
    case "engage":
      iconSrc = hand;
      altText = "palm of hand icon";
      break;
  }

  return(
    <div className={classes.join(" ")}>
      <div className="content-container">
        <div className="image-container">
          { image !== '' && <img className="post-image" src={ image } /> }
          <div className="image-filter"></div>
          <div className="icon-container">
            <img className="icon" src={iconSrc} alt={altText} />
          </div>
        </div>
        <div className="text-container">
          <p className="title">{ title }</p>
          <p>{ content }</p>
        </div>
      </div>
      <div className="footer-container">
        {isComplete
          ? <img className="status" src={checkmark} alt="two overlapped checkmarks" />
          : <button className="checkbox" onClick={handleCompleteClick}>Completed</button>
        }
      </div>
    </div>
  );
};

FullActionCard.propTypes = {
  action: PropTypes.object.isRequired,
  badge: PropTypes.string,
  completed: PropTypes.bool,
  callback: PropTypes.func
};