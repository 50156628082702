import React, { useState } from "react";
import { useAuth } from "../hooks/auth";
import { Navigate } from "react-router-dom";
import logo from "../assets/ctr-logo.png";
import { Spinner } from "../components/Spinner";
import { Link } from "react-router-dom";

export const SignupPage = () => {
  const { createUser, error, status } = useAuth();

  const [individualData, setIndividualData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    planID: ""
  });

  const handleUpdateIndividual = (event) => {
    const { value, name } = event.target;

    setIndividualData(prevState => ({
      ...prevState,
      [name] : value
    }));
  };

  const onSignup = (e) => {
    e.preventDefault();
    
    createUser(individualData);
  };
  
  if (status === "success") {
    return <Navigate to={"/login"} />;
  } else {
    return (
      <div className="signup-page">
        <div className="container-description">
          <Link to="/"><img className="logo" src={logo} alt="ConnectR Logo" /></Link>
          <div className="description">
            <h1>Start Your Journey Here</h1>
            <p>ConnectR guides your journey towards truth and reconciliation. Find calls to action to grow what you know, encourage a shared future and generate change.</p>
          </div>
          <div className="attribution">
          <a target='_blank' rel='noopener noreferrer' href="https://www.freepik.com/free-photo/wheatfield-sunset_945755.htm#fromView=search&page=1&position=2&uuid=be77f9ce-bf2b-48eb-a7f4-d5433c1dc890">Image by bedneyimages on Freepik</a>
          </div>
        </div>
        {status === "resolving" && <Spinner loading={true} />}
        <div className="container-form">
          <h2>Welcome To ConnectR</h2>
            <form className="form" onSubmit={onSignup}>
              <div className="name">
                <label>
                  <p>First Name</p>
                  <input type="text" value={individualData.firstName} name="firstName" onChange={handleUpdateIndividual} />
                </label>
                <label>
                  <p>Last Name</p>
                  <input type="text" value={individualData.lastName} name="lastName" onChange={handleUpdateIndividual} />
                </label>
              </div>
              <label>
                <p>Email</p>
                <input type="email" value={individualData.email} name="email" onChange={handleUpdateIndividual} required />
              </label>
              <label>
                <p>Password</p>
                <input type="password" value={individualData.password} name="password" onChange={handleUpdateIndividual} required />
              </label>
              <label>
                <p>Organization ID#</p>
                <input type="text" value={individualData.planID} name="planID" onChange={handleUpdateIndividual} required />
              </label>
              <div className="submit-btn-container">
                {error.isError && <p className="error">{error.message}</p>}
                {status === "resolving" ? <input type="submit" value="Sign Up" disabled /> : <input type="submit" value="Sign Up" />}
              </div>
            </form>
            <p className="additional-links">Already had an account? <Link to="/login">Sign In</Link></p>
            {/* <p className="additional-links">Or are you wanting to <Link to="/">Sign up an Organization</Link></p> */}
        </div>
      </div>
    );
  }
};
